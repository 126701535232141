import MiniDrawer from "./components/navigation";

const App = () => {
  return (
    <MiniDrawer >

    </MiniDrawer>
  );
}

export default App;
