import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Grid from '@mui/material/Grid';
import Element from './element'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PhoneIcon from '@mui/icons-material/Phone';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [home, setHome] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleHomeOpen = () => {
        setHome(true);
    };

    const handleHomeClose = () => {
        setHome(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {!open &&
                        <Typography variant="h6" noWrap component="div">
                            Hirafi
                        </Typography>
                    }
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem button key={0}>
                        <ListItemIcon>
                            <HomeIcon onClick={handleHomeOpen} />
                        </ListItemIcon>
                        <ListItemText primary='Home' />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button key={0}>
                        <ListItemIcon>
                            <ConnectWithoutContactIcon onClick={handleHomeClose} />
                        </ListItemIcon>
                        <ListItemText primary='Contact' />
                    </ListItem>
                </List>
            </Drawer>
            {home &&
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Element name="waghy" speciality="electricien" description="description" detail="detail" photo_profile="https://scontent.forn3-1.fna.fbcdn.net/v/t39.30808-6/215803821_1461247557565367_536525162543176716_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeEGvQJX41PsxFucoofiMVIN_QK-okRru3P9Ar6iRGu7c1ePDusqIxPKNRu3QkI1lZoSEX1GnnkjGCy9DL_o8alA&_nc_ohc=6c_ikc1XykUAX_b4zgy&_nc_ht=scontent.forn3-1.fna&oh=6b6bcab410ab48b72f94a141dbf4ffc0&oe=617DEF53"></Element>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Element name="ahmed" speciality="macon" description="description" detail="detail" photo_profile="https://scontent.forn3-1.fna.fbcdn.net/v/t1.6435-1/p240x240/117556794_310215487060058_5703953660390532889_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=7206a8&_nc_eui2=AeHDcvpzQ0SycRLNjj6fKen2-FzFLazZMFj4XMUtrNkwWMcRpscdylhsFtrd64NEit8tK8hR4GLRz8oEeuxZX0Sw&_nc_ohc=d4jtGfoiU9QAX_Ddgny&_nc_ht=scontent.forn3-1.fna&oh=e0083d0af77bbde191b37d42e0c1ad4f&oe=619F0167">2</Element>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Element name="hamid" speciality="plombier" description="description" detail="detail" photo_profile="https://scontent.forn3-1.fna.fbcdn.net/v/t1.6435-9/226330978_304161648130695_2002458023368892573_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeF6m7CHL-HAGNJlF27eWhL5YjM4mujITdJiMzia6MhN0o7b_SsW3OwJAegBmkWpyLCGo1h2uwq6HZen1Luf2has&_nc_ohc=9twuhysQVVYAX8P5mIG&_nc_ht=scontent.forn3-1.fna&oh=b99c49705fab56ef2eabc99f5d0a3f71&oe=619D98B8">3</Element>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Element name="yasser" speciality="aluminuim" description="description" detail="detail" photo_profile="https://scontent.forn3-2.fna.fbcdn.net/v/t1.6435-9/241167390_684009813000406_7202356131229936502_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=09cbfe&_nc_aid=0&_nc_eui2=AeFhXXwPmClhNAGhm28C97jr6dBO19BPjj3p0E7X0E-OPehYPUTGgfSee_JTOm9G0QgGTOMQ681ABoZTDRH_sdFr&_nc_ohc=_40RK3S_uXIAX_hS_mA&_nc_ht=scontent.forn3-2.fna&oh=ad39b1cd9e2cad6a9d1f38f548f6d532&oe=619EB7B9">4</Element>
                        </Grid>
                    </Grid>
                </Box>
            }
            {!home &&
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <Grid container spacing={2}>
                        <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                            <FacebookIcon fontSize="large" />
                        </Grid>
                        <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
                            <Typography>
                                Hirafi - حرفي
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                            <InstagramIcon fontSize="large" />
                        </Grid>
                        <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
                            <Typography>
                                HirafiHirafi
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                            <PhoneIcon fontSize="large" />
                        </Grid>
                        <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
                            <Typography>
                                0557950075
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                            <YouTubeIcon fontSize="large" />
                        </Grid>
                        <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
                            <Typography>
                                HirafiDz
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    );
}
